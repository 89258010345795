import { Grid } from "@mui/material";
import React from "react";
import MaskGroup from "Assets/common/MaskGroup.png";
import MaskGroup2 from "Assets/common/maskgroup2.png";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  h1Label: {
    fontWeight: "bold",
    fontSize: "72px",
    marginBottom: "10px",
    lineHeight: "1.3",
    [theme.breakpoints.down("md")]: {
      lineHeight: "1.2",
      fontSize: "32px",
    },
  },
  h1Text: {
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "1.3",
    [theme.breakpoints.down("sm")]: {
      lineHeight: "1.2",
      fontSize: "12px",
    },
  },
  textWrap: {
    [theme.breakpoints.down("md")]: {
      maxWidth: "40vw !important",
    },
  },
  h2Text: {
    fontWeight: "bold",
    fontSize: "16px",
    [theme.breakpoints.down("md")]: {
      fontSize: "10px",
    },
  },
  headingContainer: {
    minHeight: "50vh",
    // backgroundImage: `url(${MaskGroup})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "bottom center",
    // marginBottom: '10vh',
    position: "relative",
    [theme.breakpoints.down("md")]: {
      minHeight: "50vmin",
    },
  },
  bannerImageWrap: {
    width: "100%",
    margin: "0 auto",
    "& img": {
      width: "100%",
      objectFit: "cover",
      //   minHeight: "calc(100vh - 118px)",
      maxHeight: "calc(100vh - 118px)",
      // [theme.breakpoints.down("md")]: {
      // minHeight: "50vmin",
      // maxHeight: "50vmin",
      // },
    },
  },
}));

const HeroBox = ({
  heroBoxDetails,
  data,
  hideHex = false,
  imageURL = MaskGroup,
  textMaxWidth = "750px",
  paddingRight = "10%",
  paddingLeft = "10%",
}) => {
  const classes = useStyles();
  if (hideHex) {
    imageURL = MaskGroup2;
  }
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      className={classes.headingContainer}
      // style={{ backgroundImage: `url(${imageURL})` }}
    >
      <figure className={classes.bannerImageWrap}>
        <img src={heroBoxDetails?.backgroundImage} alt={data?.h1Label} />
      </figure>
      <Grid
        item
        style={{
          position: "absolute",
          top: "0",
          left: "0",
          width: "100%",
          height: "100%",
          // backgroundColor: "rgba(0,0,0,0.5)",
          zIndex: "1",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Grid
          container
          justifyContent="center"
          alignItems="start"
          style={{
            flexDirection: "column",
            paddingRight: paddingRight,
            paddingLeft: paddingLeft,
          }}
        >
          <Grid item>
            <Grid
              container
              className={classes.textWrap}
              style={{ maxWidth: `${textMaxWidth}` }}
            >
              <Grid item xs={12} style={{}}>
                <h1
                  className={classes.h1Label}
                  style={{
                    color: `${heroBoxDetails?.headingColor}`,
                    textAlign: heroBoxDetails?.headingAlign,
                  }}
                >
                  {heroBoxDetails?.heading}
                </h1>
              </Grid>
              <Grid item xs={12}>
                {heroBoxDetails?.subheading && (
                  <p
                    className={classes.h2Text}
                    style={{
                      color: `${heroBoxDetails?.subheadingColor}`,
                      textAlign: heroBoxDetails?.subheadingAlign,
                      marginTop: "10px",
                    }}
                  >
                    {heroBoxDetails?.subheading}
                  </p>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default HeroBox;
